
<template>
  <div id="apply-chat">
    <v-progress-circular
      class="ma-3"
      v-if="loadingData"
      :size="50"
      color="amber darken-1"
      indeterminate
    ></v-progress-circular>
    <ChatMode
      v-if="ready"
      :override="override"
      :overrideCurrent="overrideCurrent"
      :upil="upil"
      key="Template"
      :avatar="TruffleLogo"
      @eventWithLabel="eventsWithLabels"
      @update:currentEvent="currentEvent = $event"
      :types="types"
      :locale="$i18n.locale"
      :transformReplyVariables="transformReplyVariables"
      :botTypingDurationInMsPerMessage="400"
    />
  </div>
</template>

<script>
import { UPILCore } from '@appsocially/userpil-core'
import { ChatBot, setupListeners } from '@appsocially/vue-upil'
import { overrideCurrent } from '@/components/overrideCurrent/apply'
import { override } from '@/components/override/apply'
import script from '@/upil-scripts/apply'
import TruffleLogo from '@/assets/truffle_logo.png'
import { listeners } from '@/listeners/apply'
import { eventsWithLabels } from '@/listeners/apply-labeled-events'
import types from '@/helpers/types'
import { createNamespacedHelpers } from 'vuex'
import transformReplyVariables from './transformReplyVariables'
const { mapState: authState } = createNamespacedHelpers('auth')

const { ChatMode } = ChatBot

let isCompleted = false

export default {
  components: {
    ChatMode
  },
  props: {
    ownerId: {
      type: String,
      required: true
    },
    campaignInfo: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      upil: new UPILCore(),
      TruffleLogo,
      overrideCurrent,
      override,
      listeners: null,
      eventsWithLabels: () => {},
      types,
      currentEvent: null,
      transformReplyVariables
    }
  },
  computed: {
    ...authState(['uid']),
    loadingData () {
      const hasNode = this.currentEvent && this.currentEvent.event && this.currentEvent.event.node
      const isEndEvent = hasNode && this.currentEvent.event.isEndEvent
      const isExternal = hasNode && this.currentEvent.event.node.type === 'external'
      const isScenario = hasNode && this.currentEvent.event.node.type === 'scenario' && !isEndEvent
      return isExternal || isScenario
    },
    ready () {
      return this.upil
    }
  },
  watch: {
    uid: {
      immediate: true,
      handler (uid) {
        if (uid) {
          this.start()
        }
      }
    }
  },
  methods: {
    start () {
      const upil = new UPILCore()
      setupListeners({ upil, listeners: listeners(this.ownerId, this.uid, this.campaignInfo) })
      this.eventsWithLabels = (params) => {
        if (params.event && params.event.isEndEvent) {
          isCompleted = true
        }
        eventsWithLabels(this.ownerId, this.uid)(params)
      }
      upil.startRaw(script)
      this.upil = upil
    }
  },
  mounted () {
    isCompleted = false
    document.body.onbeforeunload = function () {
      if (!isCompleted) {
        return 'このページを離れてもよろしいですか？'
      }
    }
  },
  beforeDestroy () {
    document.body.onbeforeunload = null
  }
}
</script>

<style scoped>
#apply-chat {
  --upil-bot-bubble-color: #f1f0f0;
  --upil-bot-text-color: black;
  --upil-user-bubble-color: #FDD64E;
  --upil-user-text-color: black;
}
#apply-chat >>> [data-side="user"] .upil-text-bubble > .v-card__text {
  color: var(--upil-user-text-color) !important;
}
#apply-chat >>> [data-side="user"] .upil-text-bubble {
  background-color: var(--upil-user-bubble-color) !important;
}
#apply-chat >>> .upil-text-bubble > .v-card__text {
  color: var(--upil-bot-text-color) !important;
}
#apply-chat >>> .upil-text-bubble {
  background-color: var(--upil-bot-bubble-color) !important;
  box-shadow: unset !important;
}
#apply-chat >>> .bubble-container {
  margin: 0;
  padding: 0;
}
</style>
