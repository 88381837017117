export function overrideCurrent (context, node, component) {
  const { scenarioEnded } = context
  if (scenarioEnded) {
    return () => import('./empty')
  } else {
    return overrideLabel(context, node, component)
  }
}

function overrideLabel (context, node, component) {
  switch (node.label) {
    case 'appointmentDate':
      return () => import('./appointment-selector')
    // case 'selectLanguage':
    //   return () => import('./language-selector')
    case 'videoUpload':
      return () => import('./video-uploader')
    // case 'email':
    //   return () => import('../shared/email-input')
    default:
      return component
  }
}
