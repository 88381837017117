import { analyticsEvent, EVENT_NAMES } from '@/helpers/analytics'

const desiredEventTypes = ['dialog', 'select', 'multi-select', 'template']

export const eventsWithLabels = (ownerId, uid) => ({ event, inputState }) => {
  const { status, node: { type, label } } = event
  const isDesiredEventNodeType = desiredEventTypes.includes(type) && status === 'CONSUMED'
  if (isDesiredEventNodeType) {
    switch (event.name) {
      case 'DIALOG_START':
        analyticsEvent(EVENT_NAMES.SCENARIO_LABEL_SEEN, {
          type,
          label
        })
        break
    }
  }
}
