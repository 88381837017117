import VueI18n from 'vue-i18n'

const messages = {
  en: { message: {
    minutes: 'one minute | {minutes} minutes'
  } },
  ja: { message: {
    minutes: '{minutes}分'
  } }
}

function handleSingleDate ({ isoDate, duration, startTime }, i18n) {
  return `${isoDate} ${startTime} （${i18n.tc('message.minutes', duration, { minutes: duration })}）`
}

function handleMultipleDates ({ proposedDates }, i18n) {
  return proposedDates.map(({ iso, duration }) => `${iso} （${i18n.tc('message.minutes', duration, { minutes: duration })}）`).join('\r\n')
}

function calculateAppointmentDateReply (eventValue, i18n) {
  const isSingleDate = !eventValue.proposedDates
  const handler = isSingleDate ? handleSingleDate : handleMultipleDates
  return handler(eventValue, i18n)
}

export default function transformReplyVariables ({
  node: {
    event: { value },
    label
  },
  locale
}) {
  if (label === 'appointmentDate') {
    const i18n = new VueI18n({
      locale,
      messages
    })
    return calculateAppointmentDateReply(value, i18n)
  } else {
    return value
  }
}
