<template>
  <Chat :ownerId="ownerId" :campaignInfo="campaignInfo" />
</template>

<script>
import { serviceName } from '@/truffle.config'
import Chat from '@/components/Chat/apply'
// import { getQRCode } from '../helpers/firebase-functions'
import { setAsGuest, EVENT_NAMES, analyticsEvent, decodeCampaignInfo } from '@/helpers/analytics'
import { createNamespacedHelpers } from 'vuex'
import { firestore } from '@/helpers/firestore'
const { mapActions: mapActionsCalendar } = createNamespacedHelpers('calendar')

const style = 'margin: 0; height: 100%; overflow: hidden;'

export default {
  metaInfo: {
    htmlAttrs: {
      style
    },
    bodyAttrs: {
      style
    }
  },
  components: {
    Chat
  },
  props: {
    ownerId: {
      type: String,
      required: true
    }
  },
  data: () => ({
    campaignInfo: null
  }),
  methods: {
    ...mapActionsCalendar(['skipAuthenticateSchedulerlyClient', 'setCalendarSubscriptionInfo'])
  },
  async created () {
    this.skipAuthenticateSchedulerlyClient({ ownerId: this.ownerId })
    this.campaignInfo = this.$route.query.campaignInfo
    setAsGuest()
    // this.$vuetify.theme.dark = false
    this.setCalendarSubscriptionInfo({ ownerId: this.ownerId, scenarioId: process.env.VUE_APP_RECRUIT_SCENARIO_ID })

    const campaignInfo = (this.campaignInfo) ? decodeCampaignInfo(this.campaignInfo) : null

    // Tell applicant that this QR code is no longer used
    const { ownerId, ownerScenarioId, campaignId } = campaignInfo

    // could not use below, because "not logged in"
    // const qrCode = await getQRCode({ ownerId, scenarioId: ownerScenarioId, qrCodeId: campaignId })
    let qrCode =
      await firestore
        .collection('OWNERS')
        .doc(ownerId)
        .collection('SCENARIO_PUBLIC')
        .doc(ownerScenarioId)
        .collection('QR_CODES')
        .doc(campaignId)
        .get()
    qrCode = qrCode.exists ? qrCode.data() : null
    if (qrCode && qrCode.archived) {
      this.$router.replace('/qr-code-inactive')
      return
    }

    const gtagdata = {
      owner_id: this.ownerId,
      // owner_name: '{owner name}',
      page_type: 'hiring_bot',
      owner_scenario_id: process.env.VUE_APP_RECRUIT_SCENARIO_ID,
      campaign_id: (campaignInfo) ? campaignInfo.campaignId : null,
      campaign_type: (campaignInfo) ? campaignInfo.campaignType : null
    }
    window.gtag('event', 'page_view', gtagdata)
  },
  mounted () {
    analyticsEvent(EVENT_NAMES.LOADED_APPLY)

    // set title tag
    const campaignInfo = (this.campaignInfo) ? decodeCampaignInfo(this.campaignInfo) : undefined
    if (this.ownerId && campaignInfo && campaignInfo.ownerScenarioId) {
      firestore
        .collection('OWNERS')
        .doc(this.ownerId)
        .collection('SCENARIO_PUBLIC')
        .doc(campaignInfo.ownerScenarioId)
        .get()
        .then((doc) => {
          if (doc.exists) {
            const data = doc.data()
            if (data.companyNameJa) {
              document.title = `面接予約チャットボット (${data.companyNameJa}) by ${serviceName}`
            }
          }
        })
    }
  },
  beforeMount () {
    this.$nextTick(() => {
      this.$vuetify.theme.dark = true
      this.$nextTick(() => {
        this.$vuetify.theme.dark = false
      })
    })
  }
}

</script>

<style>
html,
body {
  margin: 0;
  height: 100%;
  overflow: hidden;
}
</style>
