export function override (context, node, component) {
  return overrideLabel(context, node, component)
}

function overrideLabel (context, node, component) {
  if (node.reply === true && node.label) {
    switch (node.label) {
      case 'videoUpload':
        return () => import('./video-uploader')
      default:
        return component
    }
  } else {
    return component
  }
}
